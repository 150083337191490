/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/headroom.js@0.9.4/dist/headroom.min.js
 * - /npm/jquery-autocomplete-js@1.1.0/autocomplete.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/bootstrap@4.0.0/dist/js/bootstrap.min.js
 * - /npm/scrollreveal@3.4.0/dist/scrollreveal.min.js
 * - /npm/paroller.js@1.4.4/dist/jquery.paroller.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
